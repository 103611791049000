.locationsearch{
    display: flex;
    justify-content: space-between;
} 
.mdltkl{
    display:flex;
    padding: 25px;
} 
.locsrch{
    margin-top: 30px;
    display: flex;
    border: 1px solid black;
    height: 32px;
    border-radius: 20px !important;
    padding-left: 15px;  
    margin-right: 30px;
    background-color: rgb(228, 245, 247);
}
.sarch{
    height: 30px;
    font-size: 14px;
    border: none !important;
    background-color: rgb(228, 245, 247);
}
.serciconn{
    width: 40px;
    background-color: #7c003f;
}
.items{
    display:flex;
    overflow-x: auto;
    margin-top: 30px;
}

.itm1{
    background-color: #7c003f; 
    color: white;
    font-size: 14px; 
    font-weight: bold;
    padding: 5px;
    border-radius: 10px; 
}
.itm{
    background-color: rgb(228, 245, 247); 
    font-size: 12px;    
    font-weight: bold;
    padding: 6px;
    border-radius: 10px;
    margin-left: 30px;
}
.restrnd{
    display: flex;
    justify-content: space-between;
    font-size: 20px; 
    text-decoration: none;
    font-weight: bold;
    margin-top: 20px;
}
.kalfc{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;   
}
.imgfst{
    border: 1px solid #d2d2d2;
    margin-top: 20px;
    border-radius: 20px;
}

.imgfst:hover{
    transform: scale(1.02);
    box-shadow:  black;
}
.imgfst1:hover{
    transform: scale(1.02);
    box-shadow:  black; 
}
.fdimg{
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.strvewrs{
    display: flex;
    justify-content: space-between;
}
.strvew{
 display: flex;   
}
.arbindmin{
    display: flex;
    justify-content: space-between;
    font-size: 13px;
}
.descrpt{
    padding:10px ;
}
.review{
    font-size: 13px;
    margin-left: 10px;
}
.arbind{
    display: flex;
}
.indian{
    margin-left: 5px;
}
.arabian, .indian{
    background-color: rgb(228, 245, 247); 
    font-size: 12px;    
    font-weight: bold;
    padding: 6px;
    border-radius: 10px;
}
.rs{
    font-weight: bold;
}
.meta{
    width: 100%;
    height: 200px;
    margin-top: 30px;
    border-radius: 20px;
}
