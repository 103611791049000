.checkout{
    margin-top: 30px;
}
.out{
    font-size: 20px;
    font-weight: 700;
    color: #7c003f;
}
.vacct{
    display: flex;
    margin-top: 20px;
}
.acc{
    font-size: 24px;
    font-weight: 600;
}
.tick{
    background-color: green;
    border-radius: 40px;
    height: 20px;
    width: 20px;
    margin-top: 11px;
    margin-left: 6px;
}
.division{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 20px;
    border-radius: 20px;
    padding: 20px;
   
}
.btnm{
    font-size: 10px;
    margin-bottom: unset;
}
.vadel{
    display: flex;
    margin-top: 30px;
}
.smdiv{
    margin-top: 20px;
    border-radius: 10px;
    padding: 10px;
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.butt{
    border: none !important;
    box-shadow: none !important;
}
.hommin{
    font-size: 14px;
    font-weight: 700;
}
.addpara{
    font-size: 10px;
    margin-top: 10px;
    font-weight: 700;
}
.btddiv{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.chdiv{
    margin-top: 20px;
}
.chan{
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    margin-top: 20px;
    color: #7c003f;
    font-weight: bold;
}
.homin{
    font-size: 16px;
    font-weight: 700;
}
   
.kptp{
    font-size: 13px;
    font-weight: 700;
    margin-top: 15px;
}
.codv{
    display: flex;
    justify-content: center;
}
.coddiv{
  border: 1px solid black;
  width: 100px;
  height: 60px;
  margin-top: 20px;
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
 
}
.coddiv:hover{
    transform: scale(1.02);
    box-shadow:  black;
}
.cod{
    padding: 15px;
    padding-left: 30px;
}
.paydiv{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}
.smdivision{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 20px;
    border-radius: 20px;
    padding: 10px;
}
.imgminplus{
    display: flex;
}
.minplus{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    width: 50px; 
    height: 25px; 
    margin-left: 10px;
    margin-top: 7px;
}
.mipl{
    display: flex;
    justify-content: space-between;
    padding-left: 6px;
    padding-right: 5px;
}
.covid{
    background-color: #f5f5f5;
    padding: 10px;
}
.srchcpn{
    margin-top: 15px;
    border: 1px dashed rgb(97, 94, 94);
    border-radius: 5px;
    
}
.coupon{
    height: 50px;
    border: none;
}
.apply{
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
}
.checkfoot{
   background: #d6d4d4;
   text-align: center;
   padding: 30px;
   margin-top: 50px;
}
.implus{
    box-shadow: 0px 1px 3px 1px rgb(116 116 116 / 20%);
    background-color: #f5f5f5;
    margin-top: 20px;
    border-radius: 20px;
    padding: 8px;
    width: 172px;
    margin-bottom: 20px;
}
@media(max-width:767px){
    .pop{
        margin-top: 20px !important;
    }
}
.applybtn, .btd, .pay{
    border: none !important;
    box-shadow: none !important;
}