.loginbox{
    box-shadow: 0 4px 8px 0 rgb(0 0 0 /20%), 0 6px 20px 0 rgb(0 0 0 /19%);
    margin:20px auto 30px;
    width: 100%;
    max-width: 500px;
    display: block;
}
.box-inside{
    background: #ffffff;
    padding: 20px;
    z-index: 20;
    box-shadow: 0 0 1px 0 #ccc;
    -webkit-box-shaddow: 0 0 1px 0 #ccc;
    -moz-box-shadow:0 0 1px 0 #ccc;
    border-radius: 10px;
    margin-top: 90px;

}
.ttx{
    width: 100%;
    height: 20px;
}
.losg{
    border: none !important;
    box-shadow: none !important;
}