.header{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.metlogometro{
    display: flex;
}
.metlogo{
    width: 100px;
}
.metro{
    margin-left: 20px;
    font-size: 30px;
    color:  #7c003f;
    font-weight: bold;
}
.losi{
    display: flex;     
}
.login{
    margin-right: 20px;
}
.btn1{
    background-color: #fff; 
    border: none;  
    border-radius: 30px;
    height: 30px;
    font-size: 12px;
    width: 100px;    
    text-decoration: none;
    color: #7c003f;
    font-weight: bold;
    margin-right: 15px;
    text-align: center;
    padding: 3%;
}
.btn1:hover{
    transform: scale(1);
    background-color: #7c003f;
    color: #fff;
}
.btn2{
    background-color:  #7c003f; 
    border: none; 
    border-radius: 30px; 
    border-radius: 30px;
    height: 30px;
    font-size: 12px;
    width: 100px; 
    padding: 10px;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    text-align: center;
    padding: 3%;
    margin-right:30;
    
}
.btn2:hover{
    transform: scale(1);
    background-color: #fff;
    color: #7c003f;
}
.imgside2{
    margin-top:30px ;
    width: 100%;
    border-radius: 10%;
    
}
.para{
    margin-top: 20px;
    
}
.para1{
    font-size: 14px;
    color: rgb(197, 176, 176);
}
.location{
  display: flex;
  width: 100%;   
  border: 1px solid  #7c003f;
  margin-top: 25px;  
  
}
.searchbx{
    width: 100%;
}
.searchbox{
    width: 100% !important;
    border: none !important;
    box-shadow: none !important;
    height: 40px;   
}
.searchicon{
    display: flex;
    background-color:  #7c003f;
    width: 50px;
    justify-content: center;
  }
  .video{
      width: 100%;
      height: 400px;
      margin-top: 30px;
      border-radius: 30px;   
      margin-bottom: 30px;
  }
  .imghead{
      margin-top: 30px;
  }
  .imgside1, .imgfry3, .imgsamu{
      width: 100%; 
      height: 300px;
      border-radius: 30px;
      padding: 10px;
      
  }
.imgfry5, .imgdelvry{
    width: 100%;
    height: 450px;
    border-radius: 30px;
    padding: 10px;
}
@media(max-width:420px){
    .imgfry5, .imgdelvry{
        height: 300px;
    } 
}
@media(max-width: 450px){
.searchbox{
    width: 350px
}
}
@media(max-width:420px){
    .metlogo{
        width: 70px;
    }
}
.pros{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2); 
    margin-top: 30px;
    border-radius: 20px;
    padding: 20px;
}
.picc{
    width: 100px;
    height: 80px;
}
.searchItem {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  .dataResult {
    width: 100%;
    max-height: 350px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
    /* position: absolute; */
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }