.headhot{
    padding: 10px;
    padding-top: 20px;
    width: 100%;
    z-index: 1000;
    background-color: #ffffff;
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
}
.locapp{
    display: flex;
}
.rsfcfa{
  display: flex;
  justify-content: space-between;
}
.imgfr3{
  margin-top: 10px;
  width: 100%;
  height: 180px;
}
.offer{
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
  border-radius: 20px;
  height: 180px;
  position: relative;
  margin-bottom:15px;
}
.offer-head{
  position: absolute;
  top:-10px;
  margin-left: 30px;
  background-color: white;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
}
.vegonly{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  font-size: 14px;
  height: 30px;
  width: 90px;
  padding: 3px; 
  z-index: 1;
  margin-bottom:15px;
}
.srchere{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  margin-left: 5px;
  z-index: 1;
  margin-bottom:15px;
}
.srchhere{
  height: 28px;
  border: none;
}
.fifav{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  font-size: 14px;
  height: 30px;
  width: 90px;
  margin-bottom:15px;
  padding: 3px; 
  z-index: 1;
}
.fifa{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  font-size: 14px;
  height: 30px;
  width: 30px;
  margin-bottom:15px;
  padding: 5px; 
  z-index: 1;
}
.middiv, .midiv{
  box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  position: relative;
 
}
.imfr{
  height: 120px;
  width: 150px;
}
.addv {
  position: absolute;
  bottom: 8px;
  right: 60px;
  background-color: white;
  text-align: center;
  font-weight: 600;
  box-shadow: 0px 1px 3px 1px rgb(116 116 116 / 20%);
  border-radius: 5px;
  width: 70px;
  height: 25px;
}
.fixdet{
  position: sticky;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #fff;
  z-index: 1000;
  box-shadow: 0px 1px 3px 1px rgb(116 116 116 / 20%);
}
@media(max-width:767px){
  .imgfr3{
    width: 50%;
    display: flex;
    justify-content: center;
  }
}
@media(min-width:768px)and(max-width:991px){
  .vegonly{
    font-size:10 !important;
  }
}
@media(max-width:991px){
  .fixdet{
    position: relative !important;
  }
}
@media(max-width:400px){
  .srchere{
    width: 120px;
  }
}

  @media(max-width:767px){
    .offer, .htp{
      margin-top: 30px;
    }
  }