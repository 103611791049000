.section1{
    margin-top: 130px;
    border: 1px solid #d2d2d2;
    padding: 10px;
    box-shadow: black;
    border-radius: 20px;
}

@media (max-width: 767px){
    .section1{
        margin-top: 20px !important;
    }
}
.word{
   font-size: 20px;
   font-weight: bold;
   font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
   color: #7c003f;
}
.aset{
    width: 130px;
    margin-top: 10px;
}
.section2{
    margin-top: 20px;
    border: 1px solid #d2d2d2;

    box-shadow: black;
    border-radius: 20px;
}
.imgfst{
    border: 1px solid #d2d2d2;
    margin-top: 40px;
    border-radius: 20px;
}
.sup{
    font-size: 45px;  
    color: #7c003f;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
}
@media(min-width: 768px) and (max-width: 1199px){
    .sup{
        font-size: 25px !important;
    }
}
@media(min-width: 768px) and (max-width: 991px){
    .section1, .banner{
        margin-top: 150px !important;
    } 

}
@media(min-width: 992px) and (max-width: 1199px){
    .section1, .banner{
        margin-top: 130px !important;
    } 

}
@media(max-width: 767px){
    .banner{
        margin-top: 230px !important;
    } 
}

/* .ps{
    font-size: 23px;
    font-weight: 650;
    color: rgb(78, 81, 82);
    margin-left: 10px;
    margin-top: 10px;
}
.mask{    
    width: 100%;
    margin-top: 80px;
    border-end-end-radius: 19px;
    border-end-start-radius: 19px;
}
.cusine{
    margin-left: 10px;
    font-size: 16px;
    margin-top: 10px;
}
.india{
    display: flex;
    justify-content: space-between;
}
.ind{
    background-color: rgb(228, 245, 247); 
    border: none;  
    font-size: 18px;    
    text-decoration: none;
    text-align: center;
    padding: 3px;
    border-radius: 10px;
    margin-top: 6px;
    margin-left: 15px;
}
.ind1{
    background-color: rgb(228, 245, 247); 
    border: none;  
    font-size: 18px;    
    text-decoration: none;
    text-align: center;
    padding: 3px;
    border-radius: 10px;
    margin-top: 6px;
    margin-right: 15px;   
} */