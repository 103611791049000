.dawmin{
    background-color: #f5f5f5;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    height: 130px;
}
.edit{
    color:blue;
    background-color: white;
    padding: 5px;
    font-size: 14px;
    height: 30px;   
    display: flex;
}
.pastdiv{
    box-shadow:  0px 1px 3px 1px rgba(116, 116, 116, 0.2);
    margin-top: 20px;
    margin-bottom: 150px;
    padding: 10px;
}
.myfalo{
    background-color: #f5f5f5;
    height: 200px;
    padding: 30px;
}
.picdiv{
    border: 1px solid rgb(170, 166, 166);
    padding: 25px;
    margin-top:30px;
}
.butreord{
    background-color: #7c003f;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 600;
}
    
.hrhr{
    border: 1px dashed rgb(211, 209, 209);
}