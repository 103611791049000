.book{
    margin-top: 25px;
}
.contname{
    display: flex;
    margin-left: 70px;
}
.seldt{
    font-size: 17px;
    font-weight: 600;
}
.bkhd{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 18px;
    color: #828285;
    font-weight: 500;
}


.divhr{
    border: 0;
    clear: both;
    display: block;
    background-color: #828285;
    height: 2px!important;
}
.bld{
    display: flex;
    justify-content: space-between;
}
.rs{
    float: right;
}
.buttt{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
}

@media(max-width: 767px){
    .bld{
        display: inline !important;
        
        
    }
}