
.fthead{
    font-weight: bold;
    margin-top: 25px;
}
.cont{
    font-size: 13px;
    margin-top: 20px;
}
.sndbx{
    display: flex;
    border-radius: 20px;
    width: 300px;  
}
.shareicon{
    display: flex;
    background-color:  #7c003f;
    width: 50px;
    justify-content: center;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
        
}
.send path{
 stroke: white !important;
}
.sendbox{
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
}
.frmbx{
    border:
}